import { GoogleAnalyticsParams } from "@typebot-io/engine";
declare const gtag: any;

const initGoogleAnalytics = (id: string): Promise<void> =>
  new Promise((resolve) => {
    const existingScript = document.getElementById("gtag");
    if (!existingScript) {
      const script = document.createElement("script");
      script.src = `https://www.googletagmanager.com/gtag/js?id=${id}`;
      script.id = "gtag";
      const initScript = document.createElement("script");
      initScript.innerHTML = `window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
    
      gtag('config', '${id}');
      `;
      document.body.appendChild(script);
      document.body.appendChild(initScript);
      script.onload = () => {
        resolve();
      };
    }
    if (existingScript) resolve();
  });

export const sendGaEvent = (params: GoogleAnalyticsParams) => {
  if (!params.event) return;
  gtag("event", params.event.action, {
    event_category: params.event.category,
    event_label: params.event.label,
    value: params.event.value,
  });
};

export default initGoogleAnalytics;
