import { createClient, SupabaseClient } from "@supabase/supabase-js";

if (!process.env.NEXT_PUBLIC_SUPABASE_URL)
  throw new Error("Missing env.NEXT_PUBLIC_SUPABASE_URL");
if (!process.env.NEXT_PUBLIC_SUPABASE_KEY)
  throw new Error("Missing env.NEXT_PUBLIC_SUPABASE_KEY");

export let supabase: SupabaseClient;

try {
  supabase = createClient(
    process.env.NEXT_PUBLIC_SUPABASE_URL,
    process.env.NEXT_PUBLIC_SUPABASE_KEY
  );
} catch (err) {
  console.log(err);
}
