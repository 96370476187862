import React from "react";

export const NotFoundPage = () => (
  <div
    style={{
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    }}
  >
    <h1 style={{ fontWeight: "bold", fontSize: "30px" }}>404</h1>
    <h2>The bot you&apos;re looking for doesn&apos;t exist</h2>
  </div>
);
