import React, { useEffect, useMemo, useState } from "react";
import { NotFoundPage } from "../components/NotFoundPage";
import * as Sentry from "@sentry/nextjs";
import {
  Chatbot,
  ChatbotComponent,
  ChatbotAnswer,
  InputType,
  Typebot,
  PublicTypebot,
  PublicConnector,
  PublicWebhook,
  Result,
  ChatbotResult,
} from "@typebot-io/engine";
import { ErrorPage } from "./ErrorPage";
import { SEO } from "./Seo";
import { useRouter } from "next/router";
import {
  initAnalyticsConnectors,
  triggerConnectorsIfNeeded,
  triggerWebhooksIfNeeded,
} from "../services/connectors";
import {
  getResultRef,
  initResult,
  saveAnswerToSupabase,
  saveAnswersToFirebase,
  setResultAsCompletedInSupabase,
  setResultAsCompletedInFirestore,
} from "../services/results";
import { getCloudFunctionBaseUrl, omit } from "../services/utils";

const startTimer = Date.now();

export type ChatbotPageProps = {
  typebot?: PublicTypebot | Chatbot;
  url: string;
  embedHostname?: string;
  isIE: boolean;
};

export const ChatbotPage = ({
  typebot,
  url,
  embedHostname,
  isIE,
}: ChatbotPageProps) => {
  const { push, asPath } = useRouter();
  const resultRef = useMemo(() => {
    if (!typebot || typebot.version === 2) return;
    return getResultRef();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [resultId, setResultId] = useState<number | undefined>();
  const [error, setError] = useState<"500" | "offline" | "IE" | undefined>();
  const [answers, setAnswers] = useState<ChatbotAnswer[] | undefined>();

  useEffect(() => {
    clearQueryParams();
    initChatbot();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearQueryParams = () => {
    if (embedHostname) return;
    const hasQueryParams = asPath.includes("?");
    if (hasQueryParams)
      push(asPath.split("?")[0], undefined, { shallow: true });
  };

  const initChatbot = async () => {
    if (!typebot) return;
    if (typebot.version === 2) setResultId(await initResult(typebot.typebotId));
    console.log(typebot.id);
    setAdditionalInfoInSentry(typebot);
    try {
      try {
        if (typebot.version === 1) await notifyNewSession(typebot);
      } catch (e) {}
      initAnalyticsConnectors(typebot);
    } catch (err) {
      setError("500");
      Sentry.captureException(err);
      console.error(err);
    }
  };

  const notifyNewSession = (chatbot: Chatbot | Typebot) =>
    fetch(
      `${getCloudFunctionBaseUrl()}/uts?chatbotId=${chatbot.id}${
        embedHostname ? `&hostname=${embedHostname}` : ""
      }`
    );

  const handleChatbotAnswer = async (chatbotAnswers: ChatbotAnswer[]) => {
    if (
      chatbotAnswers.every(
        (answer) => answer.questionType === InputType.NO_INPUT
      ) ||
      !typebot
    )
      return;
    setAnswers([...chatbotAnswers]);
    try {
      const updatedResult = await saveResult(typebot, chatbotAnswers);
      if (!updatedResult) throw Error("Didn't return updated result");
      triggerConnectorsIfNeeded({
        result: updatedResult,
        stepIndex: [...chatbotAnswers].pop()!.stepIndex,
        connectors: typebot.connectors as PublicConnector[],
      });
      triggerWebhooksIfNeeded({
        result: updatedResult,
        stepIndex: [...chatbotAnswers].pop()!.stepIndex,
        webhooks: typebot.webhooks as PublicWebhook[],
      });
    } catch (err: any) {
      Sentry.captureException({ ...err, chatbotAnswers });
      console.error(err);
      console.log(chatbotAnswers);
      setError("500");
    }
  };

  const handleChatbotCompleted = async () => {
    if (!answers || answers.length === 0 || !typebot) return;
    try {
      const updatedResult = await (resultId
        ? setResultAsCompletedInSupabase(resultId)
        : setResultAsCompletedInFirestore(resultRef!));
      triggerConnectorsIfNeeded({
        result: updatedResult,
        stepIndex: [...answers].pop()!.stepIndex,
        connectors: typebot.connectors as PublicConnector[] | undefined,
        isTypebotCompleted: true,
      });
      triggerWebhooksIfNeeded({
        result: updatedResult,
        stepIndex: [...answers].pop()!.stepIndex,
        webhooks: typebot.webhooks as PublicWebhook[] | undefined,
      });
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
      setError("500");
    }
  };

  const saveResult = async (
    typebot: Chatbot | PublicTypebot,
    chatbotAnswers: ChatbotAnswer[]
  ): Promise<Result | ChatbotResult | undefined> => {
    return resultId
      ? saveAnswerToSupabase(
          resultId,
          chatbotAnswers.map((a) =>
            omit(a, "questionContent", "stepIndex", "questionType")
          ),
          typebot.variables.starterVariables
        )
      : saveAnswersToFirebase(
          resultRef!,
          chatbotAnswers,
          typebot.id as string,
          Math.floor((Date.now() - startTimer) / 1000),
          typebot.variables.starterVariables
        );
  };

  if (!typebot) {
    return <NotFoundPage />;
  }
  if (error || isIE) {
    return <ErrorPage error={isIE ? "IE" : error!} />;
  }
  return (
    <div style={{ height: "100vh" }}>
      <SEO {...typebot.metaData} url={url} chatbotName={typebot.name} />
      <ChatbotComponent
        chatbot={typebot}
        onChatbotAnswer={handleChatbotAnswer}
        onChatbotCompleted={handleChatbotCompleted}
        onCatchedError={(err, extras) =>
          Sentry.captureException(err, { extra: { params: extras } })
        }
      />
    </div>
  );
};

const setAdditionalInfoInSentry = (typebot: Chatbot | PublicTypebot) => {
  Sentry.setExtras({
    chatbotId: typebot.id,
    publishId: typebot.publishId,
  });
};
