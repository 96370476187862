import {
  Chatbot,
  ChatbotResult,
  ConnectorType,
  GoogleAnalyticsParams,
  PublicConnector,
  PublicGoogleSheetsParams,
  PublicTypebot,
  PublicWebhook,
  Result,
} from "@typebot-io/engine";
import gtag, { sendGaEvent } from "../lib/gtag";
import { getCloudFunctionBaseUrl, isDefined } from "./utils";

export const initAnalyticsConnectors = (chatbot: Chatbot | PublicTypebot) => {
  if (!chatbot.connectors) return;
  chatbot.connectors.forEach(async (connector) => {
    let params;
    switch (connector.type) {
      case ConnectorType.FACEBOOK_PIXEL:
        params = connector.params as { id: string };
        const { default: ReactPixel } = await import("react-facebook-pixel");
        ReactPixel.init(params.id, undefined, {
          autoConfig: false,
          debug: false,
        });
        ReactPixel.pageView();
        break;
      case ConnectorType.GOOGLE_TAG_MANAGER:
        params = connector.params as { id: string };
        const { default: TagManager } = await import("react-gtm-module");
        TagManager.initialize({
          gtmId: params.id,
        });
        break;
      case ConnectorType.GOOGLE_ANALYTICS:
        const { default: initGoogleAnalytics } = await import("../lib/gtag");
        params = connector.params as GoogleAnalyticsParams;
        await initGoogleAnalytics(params.id);
        break;
    }
  });
};

export const triggerConnectorsIfNeeded = ({
  result,
  connectors,
  stepIndex,
  isTypebotCompleted,
}: {
  connectors?: PublicConnector[];
  result?: Result | ChatbotResult;
  stepIndex: number;
  isTypebotCompleted?: boolean;
}) => {
  connectors?.forEach(async (connector) => {
    if (connector.type === ConnectorType.FACEBOOK_PIXEL && isTypebotCompleted) {
      const { default: ReactPixel } = await import("react-facebook-pixel");
      ReactPixel.track("Lead");
    }
    if (connector.type === ConnectorType.GOOGLE_ANALYTICS) {
      const params = connector.params as GoogleAnalyticsParams;
      if (
        params.event &&
        isConnectorCurrentIndex({
          stepIndex,
          connectorIndex: params.event.stepIndex,
          isTypebotCompleted,
        }) &&
        gtag
      )
        sendGaEvent(params);
      return;
    }
    const isV2 = result && "typebotId" in result;
    if (connector.type === ConnectorType.GOOGLE_SHEETS && isV2) {
      const params = connector.params as PublicGoogleSheetsParams;
      if (
        isConnectorCurrentIndexMinusOne({
          stepIndex,
          connectorIndex: params.stepIndex ?? undefined,
          isTypebotCompleted,
        })
      )
        triggerConnector(result as Result);
    }
  });
};

export const triggerWebhooksIfNeeded = ({
  webhooks,
  result,
  stepIndex,
}: {
  webhooks?: PublicWebhook[];
  result?: Result | ChatbotResult;
  stepIndex: number;
}) => {
  const isV1 = !result || !result.data || "chatbotId" in result;
  if (isV1) return;
  webhooks?.forEach((webhook) => {
    if (
      isConnectorCurrentIndexMinusOne({
        stepIndex,
        connectorIndex: webhook.stepIndex ?? undefined,
        isTypebotCompleted: (result as Result).isCompleted,
      })
    )
      triggerConnector(result as Result);
  });
};

const triggerConnector = (result: Result) =>
  fetch(`${getCloudFunctionBaseUrl()}/connectors/trigger`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify({ result }),
  });

const isConnectorCurrentIndexMinusOne = ({
  stepIndex,
  connectorIndex,
  isTypebotCompleted,
}: {
  stepIndex: number;
  connectorIndex?: number;
  isTypebotCompleted?: boolean;
}) =>
  (connectorIndex && connectorIndex - 1 === stepIndex && !isTypebotCompleted) ||
  (!isDefined(connectorIndex) && isTypebotCompleted);

const isConnectorCurrentIndex = ({
  stepIndex,
  connectorIndex,
  isTypebotCompleted,
}: {
  stepIndex: number;
  connectorIndex?: number;
  isTypebotCompleted?: boolean;
}) =>
  (connectorIndex && connectorIndex === stepIndex && !isTypebotCompleted) ||
  (!isDefined(connectorIndex) && isTypebotCompleted);
