import firebase from "firebase/app";
import {
  ChatbotAnswer,
  definitions,
  StarterVariable,
  serializeResult,
  Result,
  ChatbotResult,
  AnswerData,
  deserializeResult,
} from "@typebot-io/engine";
import { supabase } from "../lib/supabase/client";

export const saveAnswersToFirebase = async (
  resultRef: firebase.firestore.DocumentData,
  answers: ChatbotAnswer[],
  chatbotId: string,
  timeSpent: number,
  starterVariables?: StarterVariable[]
): Promise<ChatbotResult> => {
  const updatedResult: any = {
    chatbotId,
    data: answers.map((answer) => answer),
    timeSpent,
    createdDate: new Date(),
    starterVariables: starterVariables ? starterVariables : undefined,
  };
  if (starterVariables) {
    updatedResult.starterVariables = starterVariables;
  }
  await resultRef.set(updatedResult, { merge: true });
  return updatedResult;
};

export const saveAnswerToSupabase = async (
  id: number,
  data: AnswerData[],
  starterVariables?: StarterVariable[]
) => {
  const variables = (starterVariables ?? [])
    .filter((v) => v.value)
    .map((v) => ({
      name: v.name,
      value: v.value!,
    }));
  const updatedResult: Partial<Result> = {
    data,
    modifiedDate: new Date(),
    variables: variables.length > 0 ? variables : undefined,
  };
  const { data: resultData, error } = await supabase
    .from("results")
    .update(serializeResult(updatedResult))
    .match({ id });
  if (error) console.error(error);
  if (!resultData) return;
  return deserializeResult(resultData[0]);
};

export const setResultAsCompletedInSupabase = async (resultId: number) => {
  const { data, error } = await supabase
    .from<definitions["results"]>("results")
    .update({ is_completed: true })
    .match({ id: resultId });
  if (error) console.error(error);
  if (!data) return;
  return deserializeResult(data[0]);
};

export const setResultAsCompletedInFirestore = (
  resultRef: firebase.firestore.DocumentData
) => resultRef!.update({ completed: true });

export const getResultRef = () => {
  const db = firebase.firestore();
  return db.collection("chatbotResults").doc();
};

export const getCloudFunctionBaseUrl = () => {
  if (process.env.NODE_ENV !== "production") {
    return "http://localhost:5001/typebot-dev/europe-west3";
  }
  return "https://api.typebot.io/api";
};

export const initResult = async (typebotId: number) => {
  const { data, error } = await supabase
    .from<definitions["results"]>("results")
    .insert({ typebot_id: typebotId });
  if (error) {
    console.error(error);
    return;
  }
  return data![0].id;
};
